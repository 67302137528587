import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import shape1 from "../assets/images/vector/how-we-work.svg"

import discoverLogo from '../assets/images/how-we-work/discover-logo.png';
import supportLogo from '../assets/images/how-we-work/support-logo.png';
import buildLogo from '../assets/images/how-we-work/build-logo.png';
import staffLogo from '../assets/images/how-we-work/staff-logo.png';

function HowWeWorkPage() {
  return (
    <Layout>
      <SEO
        title="How We Work"
        description="The technology department can be very supportive or crucial in your business. Either way, this field requires people who are committed and capable. Therefore we work in several ways to be able to cover the technological needs of your business both way."
      />
      <div style={{ overflow: "hidden" }}>
        <section id="how-we-work" className="section-top-200">
          <span className="how-we-work-shape-1">
            <img src={shape1} alt="services-decor-shape-1" />
          </span>
          <span className="how-we-work-shape-2 d-none d-block-sm">
            <img src={shape1} alt="services-decor-shape-2" />
          </span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <div className="small-caption color-cerulean-blue mb-0">
                  We are your
                </div>
                <div className="title color-raven-black">
                  Technological Solution
                </div>
                <div className="subtitle color-russian-green">
                  The technology department can be very supportive or crucial in
                  your business. Either way, this field requires people who are
                  committed and capable. Therefore we work in several ways to be
                  able to cover the technological needs of your business both
                  way.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-top-100 section-bottom-100">
          <div className="container">
            <div
              id="discover"
              className="hww-content hww-discover row section-100"
            >
              <div className="col-md-6">
                <img className="content-logo"
                  src={discoverLogo}
                  alt="How we do Discovery to brand new services."
                />
                <div className="content-header header-discover">
                  <div className="content-title">DISCOVER</div>
                  <div className="content-subtitle">Create your plan</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-5 mt-5 mt-md-0">
                  <div className="text-center heading mb-3">How</div>
                  <div className="description">
                    <p>
                      <strong>Discover</strong> is a fundamental process before
                      development, yes! A very initial process, starting from
                      ideas, gathering requirements and consulting. Moreover,
                      this process is very critical, that determines every plan
                      for your project.
                    </p>
                    <p>
                      After <strong>Discovery</strong>, we will continue to your
                      development team, or we provide it.
                    </p>
                  </div>
                </div>

                <div>
                  <div className="heading text-center mb-3">Perfect when</div>
                  <ul className="description hww-perfect-list">
                    <li>You don't have a clear picture of your product</li>
                    <li>You need a concept to convince investors</li>
                  </ul>
                </div>
              </div>
            </div>

            <div id="build" className="hww-content hww-build row section-100">
              <div className="col-md-6 order-2 order-md-1">
                <div className="mb-5 mt-5 mt-md-0">
                  <div className="text-center heading mb-3">How</div>
                  <div className="description">
                    <p>
                      After you tell the project details, we will immediately{" "}
                      <strong>work to succeed your project</strong>, from
                      building the necessary code until implementation, you need
                      to be patient a little, wait for your ideas to come true
                      and be ready to be marketed.
                    </p>
                  </div>
                </div>

                <div>
                  <div className="heading text-center mb-3">Perfect when</div>
                  <ul className="description hww-perfect-list">
                    <li>
                      You don't have a development team but need development
                      services
                    </li>
                    <li>Your team is too busy for additional projects</li>
                    <li>
                      You have a clear concept and want to meet with the
                      development team
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 order-1 order-md-2">
                <img
                  className="content-logo"
                  src={buildLogo}
                  alt="We help you build or modify your product to suit market demands."
                />
                <div className="content-header header-build">
                  <div className="content-title">BUILD</div>
                  <div className="content-subtitle">Develop your product</div>
                </div>
              </div>
            </div>

            <div id="staff" className="hww-content hww-staff row section-100">
              <div className="col-md-6">
                <img
                  className="content-logo"
                  src={staffLogo}
                  alt="We help strengthen your existsing team by managing the staffing process."
                />
                <div className="content-header header-staff">
                  <div className="content-title">Staff</div>
                  <div className="content-subtitle">Strengthen your team</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-5 mt-5 mt-md-0">
                  <div className="text-center heading mb-3">How</div>
                  <div className="description">
                    <p>
                      We can <strong>strengthen your existing team</strong> or
                      build your team from the start. We help your organization
                      and grow together. You don't need to invite us as part of
                      the company. Although, we probably wouldn't turn down the
                      offer.
                    </p>
                  </div>
                </div>

                <div>
                  <div className="heading text-center mb-3">Perfect when</div>
                  <ul className="description hww-perfect-list">
                    <li>
                      You have trouble finding and handling development staff
                    </li>
                    <li>
                      You don't want to disturb your team with a new project
                    </li>
                    <li>
                      You want to develop faster and skip through a tiring
                      hiring process
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              id="support"
              className="hww-content hww-support row section-100"
            >
              <div className="col-md-6 order-2 order-md-1">
                <div className="mb-5 mt-5 mt-md-0">
                  <div className="text-center heading mb-3">How</div>
                  <div className="description">
                    <p>
                      <strong>Don't worry</strong> that your project will not be
                      maintained later. We are committed to our work and always
                      support our work after launch. Ranging from support,
                      managing, to development. All processes will not be
                      complicated
                    </p>
                  </div>
                </div>

                <div>
                  <div className="heading text-center mb-3">Perfect when</div>
                  <ul className="description hww-perfect-list">
                    <li>
                      You want to use support services after your product
                      launched
                    </li>
                    <li>You have a limited support team</li>
                    <li>
                      It would be best if you had additional support at certain
                      times
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 order-1 order-md-2">
                <img
                  className="content-logo"
                  src={supportLogo}
                  alt="Maintain your realibility of your product for long run."
                />
                <div className="content-header header-support">
                  <div className="content-title">SUPPORT</div>
                  <div className="content-subtitle">Maintain your needs</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact">
          <div className="contact">
            <div className="p-relative section-100 container text-center text-md-left">
              <div className="heading color-pine-green mb-2">
                Let's have further discussion!
              </div>
              <div className="mb-5 color-spring-green">
                Tell us all of your digital needs and start build your business.
              </div>
              <a
                href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
                target="_blank"
              >
                <div className="d-inline button button-primary">
                  Chat with us
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HowWeWorkPage
